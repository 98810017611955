import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuthConstant } from '@constants/auth.constant';
import { AccessTokenProperties, UserSessionKeys } from '../enums/auth.enum';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { GenericObject } from '@utils/models/Types';
import { Observable, of, Subject } from 'rxjs';
import { NavigationService } from '@services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  windowRef = window;
  private _authSub = new Subject<boolean>();

  constructor(
    private _navigationService: NavigationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {}

  isLoggedIn(): boolean {
    const accessToken = this.getAccessToken();
    return !!accessToken && !this.isSessionExpired(accessToken);
  }

  getAccessToken(): string | null {
    return localStorage.getItem(UserSessionKeys.ACCESS_TOKEN);
  }

  isSessionIdTokenValid(): boolean {
    const idToken = this.getIdToken();

    return this._isIdTokenValid(idToken, false);
  }

  getIdToken(): string | null {
    return localStorage.getItem(UserSessionKeys.ID_TOKEN);
  }

  isSessionExpired(accessToken: string) {
    const decodedAccessToken: JwtPayload = jwtDecode(accessToken);
    return Date.now() > this.getTokenExpireTime(decodedAccessToken);
  }

  isTokenCallbackValid(idToken: string): boolean {
    return this._isIdTokenValid(idToken, true);
  }

  getTokenExpireTime(decodedToken: JwtPayload) {
    const tokenExpireTime = decodedToken[AccessTokenProperties.EXPIRE_NUMERIC_DATE] || 0;
    return new Date(tokenExpireTime * 1000).getTime();
  }

  clearSession() {
    localStorage.clear();
    sessionStorage.clear();
  }

  logout() {
    this.createLogoutFrame();
  }

  createLogoutFrame() {
    const ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', environment.auth.logoutUrl);
    ifrm.style.display = 'none';
    document.body.appendChild(ifrm);
  }

  buildAdfsUrl() {
    const nonce = this._generateRandomString();
    const { url, clientId, resourceId } = environment.auth;
    const redirectPath = `${environment.BASE_URL}/${AuthConstant.oAuthUrl}`;
    localStorage.setItem(UserSessionKeys.NONCE, nonce);

    return (
      `${url}?client_id=${clientId}&response_type=${AuthConstant.responseType}` +
      `&redirect_uri=${redirectPath}&nonce=${nonce}&resource=${resourceId}`
    );
  }

  getAuthenticationObservable(): Observable<boolean> {
    if (this.isSessionIdTokenValid()) {
      return of(true);
    }
    return this._authSub;
  }

  mapUrlParams(urlFragment: string = ''): any {
    const responseParameters = (urlFragment && urlFragment.split('&')) || [];

    return responseParameters.reduce((acc: GenericObject, param) => {
      const paramMap: string[] = param.split('=');

      if (paramMap.length === 2) {
        acc[paramMap[0]] = paramMap[1];
      }

      return acc;
    }, {});
  }

  setBrowserSession(idToken: string, accessToken: string) {
    localStorage.setItem(UserSessionKeys.ID_TOKEN, idToken);
    localStorage.setItem(UserSessionKeys.ACCESS_TOKEN, accessToken);
    localStorage.removeItem(UserSessionKeys.NONCE);
  }

  emitAuthLogEvent(isSuccessful: boolean) {
    this._authSub.next(isSuccessful);
    this._authSub.complete();
  }

  authenticationRedirection(url?: string) {
    const stateUrl = url || this._router.url;
    this.clearSession();
    this._navigationService.storeRedirectUrl(stateUrl);
    this.windowRef.location.replace(this.buildAdfsUrl());
  }

  private _generateRandomString(): string {
    return crypto.randomUUID();
  }

  private _isIdTokenValid(idToken: string | null, validateNonce: boolean) {
    if (!idToken) {
      return false;
    }
    const decodedIdToken: GenericObject = jwtDecode(idToken);

    const isTokenExpired = this._isTokenExpired(this.getTokenExpireTime(decodedIdToken));

    return !validateNonce
      ? !isTokenExpired
      : !isTokenExpired && this._isNonceValid(decodedIdToken[AccessTokenProperties.NONCE]);
  }

  private _isTokenExpired(tokenExpireTime: number): boolean {
    return Date.now() > tokenExpireTime;
  }

  private _isNonceValid(nonce: string): boolean {
    return nonce === localStorage.getItem(UserSessionKeys.NONCE);
  }
}
