import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private _authService = inject(AuthService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._authService.isLoggedIn()) {
      return true;
    }

    // Not a valid session. Redirect to ADFS
    this._authService.authenticationRedirection(state.url);

    // Not reachable
    return this._authService.getAuthenticationObservable().pipe(switchMap(() => of(false)));
  }
}

export const authGuardFn: CanActivateFn = (route, state) => {
  const guard = inject(AuthGuard);

  return guard.canActivate(route, state);
};
